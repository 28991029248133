body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root {
  min-height: 100%;
}
/* ========================================================================
     Component: Preloader
 ========================================================================== */
.def-preloader {
  width: 100%;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
}
.def-preloader > * {
  vertical-align: middle;
}
.def-preloader:after {
  content: '';
  height: 100%;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.box-preloader {
  width: 100%;
  height: 100%;
  padding: 12px;
  text-align: center;
  vertical-align: middle;
}
.box-preloader > * {
  vertical-align: middle;
}
.box-preloader:after {
  content: '';
  height: 100%;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
#SPW {
  width: 234px;
  height: 28px;
  margin: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
#SPW .sp {
  top: 0;
  width: 28px;
  height: 28px;
  position: absolute;
  transform: scale(0.3);
  border-radius: 19px;
  background-color: #000000;
  animation: playSP 1500ms infinite normal;
}
@keyframes playSP {
  0% {
    transform: scale(1);
    background-color: #000000;
  }
  100% {
    transform: scale(0.3);
    background-color: #ffffff;
  }
}
#SPW #SP_1 {
  left: 0;
  animation-delay: 0.1s;
}
#SPW #SP_2 {
  left: 29px;
  animation-delay: 0.25s;
}
#SPW #SP_3 {
  left: 58px;
  animation-delay: 0.4s;
}
#SPW #SP_4 {
  left: 88px;
  animation-delay: 0.55s;
}
#SPW #SP_5 {
  left: 117px;
  animation-delay: 0.7s;
}
#SPW #SP_6 {
  left: 146px;
  animation-delay: 0.85s;
}
#SPW #SP_7 {
  left: 175px;
  animation-delay: 1s;
}
#SPW #SP_8 {
  left: 205px;
  animation-delay: 1.15s;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.text-black {
  color: #000;
}
.m-0 {
  margin: 0px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.my-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.my-5 {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.my-10 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.my-15 {
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.my-20 {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
.my-25 {
  margin-bottom: 25px !important;
  margin-top: 25px !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.p-0 {
  padding: 0px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.py-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.py-5 {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}
.py-10 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.py-15 {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
.py-20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}
.py-25 {
  padding-bottom: 25px !important;
  padding-top: 25px !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.fz-10 {
  font-size: 10px;
}
.fz-12 {
  font-size: 12px;
}
.fz-14 {
  font-size: 14px;
}
.fz-16 {
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-20 {
  font-size: 20px;
}
.fz-24 {
  font-size: 24px;
}
.fz-36 {
  font-size: 36px;
}
.fz-48 {
  font-size: 48px;
}
.fz-56 {
  font-size: 56px;
}
.sv_container {
  background-color: #f5f5f5;
}
@media (max-width: 600px) {
  .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix td::after {
    padding-left: 2em !important;
  }
}
.private-layout {
  min-height: 100vh;
}
