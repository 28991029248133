@baseSize: 5;

// NOTE loop definition
.sizes(@name, @properties, @amount, @i: 0) when (@i <= @amount) {
  // NOTE count the size
  @size: @i * @baseSize;
  // NOTE Amount of properties
  @length: length(@properties);

  .@{name}-@{size} {
    // NOTE loop through all properties
    .loop (@index) when (@index > 0){
      // NOTE get each property
      @property: e(extract(@properties, @index));
      // NOTE Use each property
        @{property}: unit(@size, px) !important;
      .loop (@index - 1);
    }
    // NOTE keep looping
    .loop(@length);
  }
  // NOTE recursive call for the next size
  .sizes(@name, @properties, @amount, @i + 1);
}

@horizontal-margin: margin-right, margin-left;
@vertical-margin: margin-top, margin-bottom;

.sizes(m, margin, 4);

.sizes(my, @vertical-margin, 5);
.sizes(mx, @horizontal-margin, 5);

.sizes(mr, margin-right, 5);
.sizes(ml, margin-left, 5);
.sizes(mt, margin-top, 10);
.sizes(mb, margin-bottom, 10);

@horizontal-padding: padding-right, padding-left;
@vertical-padding: padding-top, padding-bottom;

.sizes(p, padding, 4);

.sizes(py, @vertical-padding, 5);
.sizes(px, @horizontal-padding, 5);

.sizes(pr, padding-right, 5);
.sizes(pl, padding-left, 5);
.sizes(pt, padding-top, 5);
.sizes(pb, padding-bottom, 5);
