@fzSizes: 10, 12, 14, 16, 18, 20, 24, 36, 48, 56;

// NOTE loop definition
.fz(@name, @property, @amount, @i: 1) when (@i <= @amount) {
  // NOTE Amount of properties
  @size: extract(@fzSizes, @i);
  // NOTE Generate classes
  .@{name}-@{size} {
    font-size: unit(@size, px)
  }
  // NOTE recursive call for the next size
  .fz(@name, @property, @amount, @i + 1);
}

.fz(fz, font-size, length(@fzSizes));
